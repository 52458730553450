import React from 'react';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './ContactUsTile.scss';

const ContactUsTile = ({ data }) => (
  <div className="contact-us-tile col-sm-12 col-md-6 col-lg-5">
    <div className="contact-us-tile__box">
      <div className="contact-us-tile__content">
        <h2 className="contact-us-tile__content__title">{data.title}</h2>
        {data.subtitle ? (
          <h3 className="contact-us-tile__content__subtitle">
            {data.subtitle}
            <hr />
          </h3>
        ) : null}
        <div
          className="contact-us-tile__content__text"
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
      </div>
      <SeparatorWithGradient className="contact-us-tile__separator" />
    </div>
  </div>
);

export default ContactUsTile;
