import React from 'react';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './ContactUsTileWide.scss';

const ContactUsTileWide = ({ data }) => (
  <div className="contact-us-tile-wide">
    <div className="contact-us-tile-wide__box">
      <img className="contact-us-tile-wide__image" src={data.image.publicURL} />
      <div className="contact-us-tile-wide__content">
        <h2 className="contact-us-tile-wide__content__title">{data.title}</h2>
        {data.subtitle ? (
          <h3 className="contact-us-tile-wide__content__subtitle">
            {data.subtitle}
            <hr />
          </h3>
        ) : null}
        <div
          className="contact-us-tile-wide__content__text"
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
      </div>
    </div>
    <SeparatorWithGradient className="contact-us-tile-wide__separator" />
  </div>
);

export default ContactUsTileWide;
