import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import AfterHeroSection from 'components/AfterHeroSection/AfterHeroSection';
import ContactUsInfoContainer from 'components/ContactUsInfoContainer/ContactUsInfoContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const ContactPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.contactYaml.title} />
      <Hero data={data.contactYaml.hero} />
      <AfterHeroSection data={data.contactYaml.afterHeroSection} />
      <ContactUsInfoContainer data={data.contactYaml.contactUsInfoContainer} />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery {
    contactYaml {
      id
      title

      hero {
        size
        title

        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        separator
      }
      afterHeroSection {
        title
        subtitle
        description
        callToActionButton {
          text
          url
        }
      }

      contactUsInfoContainer {
        contactUsTiles {
          title
          subtitle
          content
          type
          image {
            publicURL
          }
        }
      }
    }
  }
`;
