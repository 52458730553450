import React from 'react';
import ContactUsTile from 'components/ContactUsTile/ContactUsTile';
import ContactUsTileWide from 'components/ContactUsTile/ContactUsTileWide';
import './ContactUsInfoContainer.scss';

const ContactUsTiles = ({ tiles }) => {
  if (tiles) {
    return tiles.map((tile, i) => {
      return tile.type === 'wide' ? (
        <ContactUsTileWide
          key={i}
          data={tile}
          largestTitle={tile.title}
          largestSubtitle={tile.subtitle}
        />
      ) : (
        <ContactUsTile data={tile} key={i} />
      );
    });
  }
  return null;
};

const ContactUsInfoContainer = ({ data }) => (
  <div className="contact-us-container">
    <div className="contact-us-container__padding-box">
      <div className="row no-gutters justify-content-around">
        <ContactUsTiles tiles={data.contactUsTiles} />
      </div>
    </div>
  </div>
);

export default ContactUsInfoContainer;
